<template>
    <ion-page>
        <ion-content :fullscreen="true" :scroll-events="true" scrollX="true" scrollY="true" overflow-scroll="true"
                     @ionScroll="toggleTimer($event)" v-bind:class="setupAppDefaultCssClasses()">
            <offline-mode v-if="offline_ref"></offline-mode>
            <div v-else :aria-hidden="testPageAriaHidden">


                <div id="test-header" class="test-header disable-printer"
                     v-bind:class="{'non-visible':!scrollPassPageTimer}">
                    <div id="test-header-button-area" class="test-header__right">
                        <ion-button fill="clear" v-if="test_settings.allow_click_previous"
                                    class="see-all-question-button bookmark-button button"
                                    @click="setOpenBookmarkModal(true)"
                                    :disabled="!state.display_contents"
                                    aria-label="Open a modal to jump to Questions on other pages."
                        >

                            <ion-icon id="bookmark-hamburger-icon" class="icon" slot="icon-only" aria-hidden="true"
                                      :src="require('../assets/icons/hamburger-menu.svg')"/>
                            <span>{{ state.language_set['test_display_prev_questions'] }}</span>
                        </ion-button>

                        <span class="username" v-if="userFullName != ''">
              <ion-icon aria-hidden="true" class="icon user"/> <span v-html="userFullName"/>
            </span>
                    </div>


                    <div id="test-header-timer" class="test-header__timer" v-if="test_settings.time_limits > 0"
                         v-bind:class="{'non-visible':!scrollPassPageTimer || state.sleep_detected}"
                         :aria-hidden="scrollPassPageTimer ? false : true">
                        <HeaderTimerBar :progress-ratio="state.timeout_progress_ratio"
                                        :timeout-remaining-time="state.timeout_remaining_time"></HeaderTimerBar>
                    </div>
                </div>

                <Header :logo-style="{'margin-bottom':'11px'}"></Header>


                <div class="template">

                    <div id="test-contents"
                         v-bind:class="{'fade-leave-to':!state.display_contents, 'fade-enter-to':state.display_contents}">

                        <Banner id="offline-soon-message" banner-type="warning" :display-icon="true"
                                :message="offlineSoonMessage"
                                v-if="offlineSoonMessage"
                                style="margin-top:15px; margin-bottom:0px;">
                        </Banner>

                        <TestTitle :display-price="false"
                                   :display-ads="state.theme.show_ads" :google-ad-slot="state.google_ad_slot">
                        </TestTitle>

                        <div id="test-title-user-area">
                            <ion-button fill="clear" v-if="test_settings.allow_click_previous"
                                        class="see-all-question-button bookmark-button button"
                                        @click="setOpenBookmarkModal(true)"
                                        :disabled="!state.display_contents"
                                        aria-label="Open a modal to jump to Questions on other pages."
                            >

                                <ion-icon class="icon" slot="icon-only" aria-hidden="true"
                                          :src="require('../assets/icons/hamburger-menu.svg')"/>
                                <span>{{ state.language_set['test_display_prev_questions'] }}</span>
                            </ion-button>

                            <span class="username" :class="{'username-border': test_settings.allow_click_previous}"
                                  v-if="userFullName != ''">
                  <ion-icon aria-hidden="true" class="icon user"/> <span class="username-text"
                                                                         v-html="userFullName"></span>
              </span>
                        </div>

                        <div id="template__timer" class="disable-printer" v-if="test_settings.time_limits > 0 "
                             v-bind:class="{'non-visible':(scrollPassPageTimer || state.sleep_detected) }"
                             :aria-hidden="scrollPassPageTimer ? true : false">

                            <TimerCard :progress-ratio="state.timeout_progress_ratio"
                                       :timeout-remaining-time="state.timeout_remaining_time"></TimerCard>
                        </div>


                        <div id="test-content-wrapper" v-if="state.display_feedback == false">
                            <div class="test-content" :id="'question-'+content.id"
                                 :data-cy="'question-idx-'+(index +1 + state.contents.prev_question_count)"
                                 v-for="(content,index) in state.contents.contents" :key="content.type+content.id"
                                 tabindex="0">
                                <question v-if="content.type=='question'" :question="content"
                                          v-on:answer_change="updateUserAnswer"
                                          v-on:flag_change="updateUserFlag"
                                          :user-answer="state.user_answers[content.id]"
                                          :question_index="index +1 + state.contents.prev_question_count"
                                          :num-of-question="state.number_of_questions"
                                          :settings="test_settings"
                                          :display-error="displayError(content.id)"
                                          :disabled="!state.display_contents"

                                ></question>
                            </div>
                        </div>
                        <div id="test-content-wrapper" v-else>
                            <div class="test-content" :id="'question-'+content.id"
                                 :data-cy="'question-idx-'+(index +1 + state.contents.prev_question_count)"
                                 v-for="(content,index) in state.contents.contents"
                                 :key="content.type+content.id" tabindex="0">
                                <question-feedback v-if="content.type=='question'"
                                                   :question="content"
                                                   :user-answer="state.user_answers[content.id]"
                                                   :question_index="index +1 + state.contents.prev_question_count"
                                                   :num-of-question="state.number_of_questions"
                                                   :feedback="state.page_feedback[content.id]"
                                                   :display-bookmarks="false"
                                ></question-feedback>
                            </div>
                        </div>


                        <div class="button-area disable-printer">
                            <ion-grid>
                                <ion-row class="button-row disable-printer">
                                    <ion-col class="ion-no-padding">
                                        <ion-button class="ion-float-left button-previous"
                                                    v-if="test_settings.allow_click_previous && (state.current_page> 1 || state.display_feedback == true)"
                                                    @click="goBack()"
                                                    :disabled="state.disable_buttons" data-cy="previous-btn">
                                            <ion-icon class="icon arrow-left" aria-hidden="true"></ion-icon>
                                            {{ state.language_set.link_previous }}
                                        </ion-button>
                                        <ion-button class="ion-float-right button-next" v-if="isNextPageAvailable"
                                                    @click="goNext()" :disabled="state.disable_buttons"
                                                    data-cy="continue-btn">

                                            {{ state.language_set.link_next }}
                                            <arrow-icon class="arrow-right" aria-hidden="true"></arrow-icon>
                                        </ion-button>
                                    </ion-col>
                                </ion-row>


                                <ion-row class="button-row disable-printer"
                                         v-if="!isNextPageAvailable || state.visit_last_page">
                                    <ion-col style="display: flex; justify-content: center;">
                                        <ion-button class="finish-button" :disabled="state.disable_buttons"
                                                    data-cy="finish-btn"
                                                    @click="setOpenCompleteTestModal(true)">
                                            {{ state.language_set.link_finish_now }}
                                        </ion-button>
                                    </ion-col>
                                </ion-row>

                                <ion-row class="button-row disable-printer" v-if="test_settings.resume_later">
                                    <ion-col style="display: flex; justify-content: center;">
                                        <ion-button class="finish-button resume-later-button" @click="saveAndExit()"
                                                    :disabled="state.disable_buttons">
                                            {{ state.language_set.link_save_finish_later }}
                                        </ion-button>

                                    </ion-col>
                                </ion-row>

                            </ion-grid>

                        </div>
                        <div class="disable-printer" v-if="state.theme.show_ads">
                            <google-ads :google-ad-slot="state.google_ad_slot"></google-ads>
                        </div>

                    </div>

                    <CmPowerByBanner></CmPowerByBanner>
                </div>
            </div>

        </ion-content>

        <ion-modal
            :is-open="isBookmarkModalOpen"
            :css-class="$setComponentOrientation('bookmark-modal')"

            @didDismiss="setOpenBookmarkModal(false)"
            backdrop-dismiss="false">
            <bookmark-modal @dismiss-bookmark-modal="setOpenBookmarkModal(false)" v-on:jump-to-page="jumpToPage"
                            :content-aria-hidden="!testPageAriaHidden"/>
        </ion-modal>

        <ion-modal
            :is-open="isCompleteTestModalOpen"
            css-class="complete-test-modal"
            @didDimsiss="setOpenCompleteTestModal(false)"
            backdrop-dismiss="false">
            <complete-test-modal
                                :is-button-disabled="isCompleteModalButtonDisabled"
                                 @dismiss-compete-test-modal="setOpenCompleteTestModal(false)"
                                 @complete-test="completeTest"></complete-test-modal>
        </ion-modal>


        <ion-loading
            :is-open="isLoadingSpinnerOpen"
            message=""
            mode="ios"
            :cssClass="$setComponentOrientation('loading-spinner-during-test')"
            spinner="dots"
            @didDismiss="setOpenLoadingSpinner(false)">
        </ion-loading>


    </ion-page>
</template>

<script>
import {
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonLoading,
    IonModal,
    IonPage,
    IonProgressBar,
    IonRow
} from '@ionic/vue';
import {computed, defineComponent, reactive, ref, watch} from 'vue';
import store from "@/store";
import Question from "../components/test/Question";
import Header from "../components/html/Header";
import QuestionFeedback from "../components/test/QuestionFeedback";
import CompleteTestModal from "../components/test/CompleteTestModal";
import {useRouter} from "vue-router";
import RouteHandler from "../router/routeHandler";
import constant from "../constant";
import TestHelper from "./TestHelper";
import BookmarkModal from "../components/test/BookmarkModal";
import timeUtil from "../utils/timeUtil";
import HeaderTimerBar from "../components/test/HeaderTimerBar";
import TimerCard from "../components/test/TimerCard";
import CmPowerByBanner from "../components/html/CmPowerByBanner";
import GoogleAds from "../components/html/GoogleAds";
import TestTitle from "../components/html/TestTitle";
import Banner from "../components/html/Banner";
import OfflineMode from "../components/test/OfflineMode";
import htmlSetupUtil from "../utils/htmlSetupUtil";
import ArrowIcon from "../components/icons/ArrowIcon";
import googleTagManagerUtils from "@/utils/googleTagManagerUtils";


export default defineComponent({
    name: 'Test',
    components: {
        ArrowIcon,
        OfflineMode,
        TestTitle,
        GoogleAds,
        TimerCard,
        HeaderTimerBar,
        CompleteTestModal,
        IonContent,
        IonPage,
        IonButton,
        Question,
        QuestionFeedback,
        Header,
        IonGrid,
        IonRow,
        IonCol, IonModal,
        IonIcon,
        BookmarkModal,
        IonLoading,
        IonProgressBar,
        IonCard,
        CmPowerByBanner,
        Banner
    },
    mounted() {

        /**
         * Dislable copy & paste depedning on the setup
         */

        const {disablePaste, disableCopy} = htmlSetupUtil();
        // disable copy & paste if it is required.
        disablePaste();
        disableCopy();

        googleTagManagerUtils().pushEventData("Start Test", "");

        let me = this;
        me.calculateTimeoutProgress();


        this.$globalEmitter.on('resume-test-from-offline', () => {
            me.autoSubmitAnswer(true);
        })

        this.$globalEmitter.on('go_back_to_student_menu', () => {

            // Submit ONLY test is in progress
            if (store.getters.testState == constant.STATUS_TEST_IN_PROGRESS) {
                me.autoSubmitAnswer(false); // when student click go_back_to_student menu, then auto submit answers
            }
        })

        const {getCurrentTimestamp} = new timeUtil();
        // let remaining_time_seconds = this.test_settings - Math.floor(new Date().getTime()/1000);
        let last_timestamp = getCurrentTimestamp();

        if (me.test_settings.time_limits !== null && me.test_settings.time_limits != 0) {
            this.timer = setInterval(function () {

                let current_timestamp = getCurrentTimestamp();
                let timegap = current_timestamp - last_timestamp;
                if (timegap > 3) {
                    me.state.sleep_detected = true;
                    last_timestamp = current_timestamp;
                    me.autoSubmitAnswer(false);
                } else {
                    last_timestamp = current_timestamp;
                    me.calculateTimeoutProgress()
                }

                if (me.state.timeout_remaining_time <= 0 && store.state.offline_status == constant.STATUS_ONLINE) {
                    // before complete the test, ensure close all the modal could possibly open
                    me.setOpenBookmarkModal(false);
                    me.setOpenCompleteTestModal(false);

                    // send timeout complete request
                    me.timeoutCompleteTest();
                }


            }, 1000);
        }

        let auto_submit_timer = 1000 * 60 * 5; // every 5 minute
        // let auto_submit_timer = 1000 * 60 * 1; // every minute

        this.auto_submit_timer = setInterval(function () {

                let current_timestamp = Math.floor(Date.now() / 1000);
                if (store.state.offline_status == constant.STATUS_ONLINE
                    // ensure auto submit the answers when user was not submited for 50 seconds.
                    && current_timestamp - me.state.last_answer_submitted_timestamp >= 50
                ) {
                    me.autoSubmitAnswer();
                }
            },
            auto_submit_timer
        );

        store.dispatch('setTestAutoSubmitTimerObj', this.auto_submit_timer);
    },
    watch: {
        /**
         * Detect URL change. If URL changes, make sure all interval events are cleared
         * @param to
         * @param from
         */
        '$route'(to, from) {
            // clear test timer
            if (this.timer) {
                clearInterval(this.timer);
            }

            //clear auto submit timer
            store.dispatch('clearTestAutoSubmitTimer');
        },
    },

    setup(props, ctx) {

        let isCompleteTestModalOpen = ref(false);
        let isBookmarkModalOpen = ref(false);
        let isLoadingSpinnerOpen = ref(false);
        let scrollPassPageTimer = ref(false);
        let offline_ref = ref(false);
        let isCompleteModalButtonDisabled = ref(false);


        const state = reactive({
            contents: store.getters.testPageContents,
            user_answers: null,
            current_page: store.getters.getCurrentTestPageNumber,
            total_page_count: store.getters.getTotalPageNumber,
            answer_updated: false,
            disable_buttons: false,
            number_of_questions: store.getters.getNumberOfQuestionInTest,
            display_feedback: false,
            page_feedback: null,
            language_set: store.getters.languageSet,
            not_answered_quiz_ids: [], // store the quiz id for answered, attempted and not answered
            visit_last_page: false,
            timeout_progress_ratio: null,
            timeout_remaining_time: null,
            display_contents: true, // This is used for animation,
            google_ad_slot: process.env.VUE_APP_GOOGLE_ADS_SLOT_TEST, // google ad slot number,
            theme: store.getters.theme,
            last_answer_submitted_timestamp: null,
            sleep_detected: false
        });


        state.visit_last_page = store.state.Test.data.test.last_page_visited;
        __updateLastAnswerSubmittedTimestamp();

        let api_used = false;

        const test_settings = store.getters.testSettings;
        __setCurrentPageUserAnswers();

        const route_handler = new RouteHandler(useRouter());
        const {convertDurationString} = new timeUtil();

        const offlineSoonMessage = computed(() => {
            let message = store.getters.testOfflineSoonMessage;
            return message;
        })


        /**
         * Get User's full name
         */

        const userFullName = computed(() => {
            return store.getters.userFullName;
        })

        /**
         * Display previous page if it is available.
         */
        function goBack() {
            state.disable_buttons = true;

            googleTagManagerUtils().pushEventData("Previous Page", "");

            //FROM feedback page
            if (state.display_feedback) {
                state.display_contents = false;

                setTimeout(() => {
                    state.display_feedback = false;
                    TestHelper.scrollToFirstQuestion(state, offlineSoonMessage);
                    state.display_contents = true;
                }, 500)
            } else {
                // FROM question page
                (() => {
                    state.display_contents = false;
                    let loading_spinner_timeout_id = TestHelper.displayLoadingSpinner(state, isLoadingSpinnerOpen);

                    let observer_interval = setInterval(function () {
                        if (!api_used) {
                            api_used = true;
                            clearInterval(observer_interval);
                            store.dispatch('Test/getPreviousPage', {
                                current_page: state.current_page,
                                user_answers: state.user_answers,
                                answer_updated: state.answer_updated
                            }).then(() => {
                                TestHelper.renderNewPage(store, state, route_handler);
                                TestHelper.scrollToFirstQuestion(state, offlineSoonMessage);

                                __updateLastAnswerSubmittedTimestamp();
                            }).catch((error) => {
                                TestHelper.handleError(error);
                            }).finally(() => {
                                api_used = false;
                                clearTimeout(loading_spinner_timeout_id);
                                state.display_contents = true;
                            });
                        }
                    }, constant.API_RETRY_TIME)
                })();
            }
        }

        /**
         *  Display next page. If user feedback is avaible, display feedback page.
         *  Otherwise display next test page.
         */
        function goNext() {
            state.disable_buttons = true;
            googleTagManagerUtils().pushEventData("Next Page", "");

            let is_valid = TestHelper.validateQuestionAnswered(state, test_settings);
            if (!is_valid) {
                state.disable_buttons = false;
                return;
            }

            // From Feedback page to next page
            if (state.display_feedback) {
                let loading_spinner_timeout_id = TestHelper.displayLoadingSpinner(state, isLoadingSpinnerOpen);
                state.display_contents = false;
                setTimeout(() => {
                    store.dispatch('Test/deletePageFeedback').then(() => {

                        TestHelper.renderNewPage(store, state, route_handler);
                        TestHelper.scrollToFirstQuestion(state, offlineSoonMessage);
                        state.display_contents = true;
                        clearTimeout(loading_spinner_timeout_id);
                    })
                }, 500);

                // From Question Page to next page
            } else {
                (() => {
                    let loading_spinner_timeout_id = TestHelper.displayLoadingSpinner(state, isLoadingSpinnerOpen);
                    state.display_contents = false;
                    let observer_interval = setInterval(function () {

                        if (!api_used) {
                            api_used = true;
                            clearInterval(observer_interval);
                            store.dispatch('Test/submitAnswer', {
                                current_page: state.current_page,
                                user_answers: state.user_answers,
                                answer_updated: state.answer_updated
                            }).then(() => {
                                let page_feedback = store.getters.getPageFeedback;
                                if (page_feedback !== null) {

                                    state.display_feedback = true;
                                    state.page_feedback = page_feedback;
                                    TestHelper.scrollToFirstQuestion(state, offlineSoonMessage);
                                } else {

                                    TestHelper.renderNewPage(store, state, route_handler)
                                    TestHelper.scrollToFirstQuestion(state, offlineSoonMessage);
                                }
                                __updateLastAnswerSubmittedTimestamp()
                            }).catch((error) => {
                                TestHelper.handleError(error);
                            }).finally(() => {
                                api_used = false;
                                state.display_contents = true;
                                clearTimeout(loading_spinner_timeout_id);
                            });
                        }
                    }, constant.API_RETRY_TIME)
                })();
            }
        }


        /**
         * Check if question is not answered and emphasize colors
         */

        function displayError(question_id) {
            return state.not_answered_quiz_ids.includes(question_id);
        }


        /**
         * Update user answer by receiving message from child components
         *
         */
        function updateUserAnswer(object) {

            if (object.question_id in state.user_answers) {
                let user_answer = state.user_answers[object.question_id];
                if (user_answer.answer != object.value.answers) {
                    user_answer.setAnswer(object.value.answers);
                    user_answer.setType(object.value.type);
                }

                // remove entry from unanswered id list if exists
                let unanswered_index = state.not_answered_quiz_ids.indexOf(object.question_id);
                if (unanswered_index >= 0) {
                    state.not_answered_quiz_ids.splice(unanswered_index, 1);
                }
                state.answer_updated = true;
            }
        }

        /**
         *  Update user flag selection by receiving message from child components
         */
        function updateUserFlag(object) {
            if (object.question_id in state.user_answers) {
                let user_answer = state.user_answers[object.question_id];
                user_answer.setFlagged(object.flagged);
                state.answer_updated = true;
            }
        }

        /**
         * Save and exist tests
         */
        function saveAndExit() {
            let copied_settings = Object.assign({}, test_settings);
            copied_settings.must_answer_question = false;
            let is_answered = TestHelper.validateQuestionAnswered(state, copied_settings);
            if (!is_answered) {
                return;
            }
            state.disable_buttons = true;
            store.dispatch('Test/saveExitTest', {
                current_page: state.current_page,
                user_answers: state.user_answers,
                timeout: false,
            }).then(() => {
                state.disable_buttons = false;
                store.dispatch('Test/deletePageFeedback').then(() => {
                    store.dispatch('Test/setState', constant.STATUS_LOGOUT);
                    route_handler.route();
                });
            }).catch((error) => {
                state.disable_buttons = false;
                TestHelper.handleError(error);
            })
        }


        /**
         * Complete the test and moving to the test results page
         */
        function completeTest() {

            state.disable_buttons = true;
            setCompleteModalButtonDisabled(true);

            if (test_settings.must_answer_question) {
                if (test_settings.allow_click_previous) {
                    let all_answered = TestHelper.validateAllAnswers();

                    if (!all_answered) {
                        let error = {
                            type: 'info',
                            message: state.language_set['test_error_unanswered_text']
                        }
                        store.dispatch('Error/display', error);
                        state.disable_buttons = false;
                        setOpenCompleteTestModal(false);
                        setCompleteModalButtonDisabled(false);
                        return;
                    }
                } else {
                    let is_answered = TestHelper.validateQuestionAnswered(state, test_settings);

                    if (!is_answered) {
                        state.disable_buttons = false;
                        setOpenCompleteTestModal(false);
                        setCompleteModalButtonDisabled(false);
                        return;
                    }
                }
            }

            (() => {
                let observer_interval = setInterval(function () {
                    if (!api_used) {

                        api_used = true;
                        clearInterval(observer_interval);
                        store.dispatch('Test/completeTest', {
                            current_page: state.current_page,
                            user_answers: state.user_answers,
                            answer_updated: state.answer_updated,
                        }).then(() => {
                            state.disable_buttons = false;
                            // set Cookie
                            route_handler.route();
                        }).catch((error) => {
                            TestHelper.handleError(error);
                        }).finally(() => {
                            api_used = false;
                            state.disable_buttons = false;
                            setCompleteModalButtonDisabled(false);
                            setOpenCompleteTestModal(false);

                        });
                    }
                }, constant.API_RETRY_TIME)
            })();
        }


        /**
         * Check if next page available or not. This defines to display next and complete buttons.
         * */
        const isNextPageAvailable = computed(() => {
            //check if current page is the last page of the test
            if (state.current_page == state.total_page_count) {
                // if display feedback and currently displaying feedback page
                if (test_settings.display_feedback_during_test && state.display_feedback) {
                    state.visit_last_page = true;
                    return false;
                }
                // if not display feedback during test
                if (!test_settings.display_feedback_during_test) {
                    state.visit_last_page = true;
                    return false;
                }
            }
            return true;
        })

        /**********************************************************
         * Complete Test Modal related functions & variables
         ******************************************************** */

        /**
         * Reference variable that modal is currently open or closed
         * @type {Ref<UnwrapRef<boolean>>}
         */

            // When state is true: open modal, false : close modal
        const setOpenCompleteTestModal = (state) => {
                isCompleteTestModalOpen.value = state;
            }

        /**********************************************************
         * BOOK MARK MODAL related functions & variables
         ******************************************************** */

        const setOpenBookmarkModal = (state) => {
            isBookmarkModalOpen.value = state;
        }

        const setCompleteModalButtonDisabled = (state)=>{
            isCompleteModalButtonDisabled.value = state;
        }

        /**
         * When a user select speicic question in th bookmark modal,
         * browser the users to the specific page and question
         */

        function jumpToPage(object) {
            store.dispatch('Test/setSectionAndPage', {
                section_index: object.section_index,
                page_index: object.page_num
            });

            if (state.answer_updated) {
                state.display_contents = false;
                setOpenLoadingSpinner(true);

                (() => {
                    let observer_interval = setInterval(function () {
                        if (!api_used) {
                            api_used = true;
                            clearInterval(observer_interval);
                            store.dispatch('Test/autoSubmitAnswer', {
                                current_page: state.current_page,
                                user_answers: state.user_answers,
                                timeout: false,
                            }).then(() => {
                                //render page and close all non necessary popups
                                TestHelper.renderNewPage(store, state, route_handler)
                                // set scroll position to selected question
                                TestHelper.scrollToQuestion(object.question_id);
                                TestHelper.delayDisplayContents(state);
                                __updateLastAnswerSubmittedTimestamp();

                            }).catch((error) => {
                                TestHelper.handleError(error);
                                state.display_contents = true;
                            }).finally(() => {
                                setOpenLoadingSpinner(false);
                                setOpenBookmarkModal(false);
                                api_used = false;
                            })
                        }
                    }, constant.API_RETRY_TIME)
                })();
            } else {
                state.display_contents = false;
                setOpenBookmarkModal(false);
                setTimeout(() => {
                    TestHelper.renderNewPage(store, state, route_handler)
                    // set scroll position to selected question
                    TestHelper.scrollToQuestion(object.question_id);
                    state.display_contents = true;
                }, 500)
            }
        }

        const setOpenLoadingSpinner = (state) => {
            isLoadingSpinnerOpen.value = state;
        }


        /**********************************************************
         * Private functions
         ******************************************************** */
        /**
         * Set User answers for the current page
         * @private
         */
        function __setCurrentPageUserAnswers() {
            let test = store.state.Test.data.test;
            state.contents = test.getCurrentPage();
            state.user_answers = test.getCurrentPageUserAnswer();
        }

        // detect error modal closes and scroll to the specific question if required.
        watch(() => store.state.Error.error.display_modal, (newValue, oldValue) => {
            if (oldValue == true && newValue == false) { // error modal open and closed
                if (state.not_answered_quiz_ids.length > 0) {
                    let question_id = state.not_answered_quiz_ids[0];
                    TestHelper.scrollToQuestion(question_id);
                }
            }
        })

        function calculateTimeoutProgress() {
            if (test_settings.time_limits > 0) {
                const time_limits = test_settings.time_limits;
                const remaining_time = store.getters.remainingTime - 1; // use BE server time to stop clock manipulation shenanigans.

                // update remaining time
                store.dispatch('Test/setRemainingTime', remaining_time);

                state.timeout_remaining_time = remaining_time;
                if (state.timeout_remaining_time < 0) {
                    state.timeout_remaining_time = 0;
                }
                state.timeout_progress_ratio = 1 - this.state.timeout_remaining_time / (time_limits);
            }
        }

        function timeoutCompleteTest() {

            state.disable_buttons = true;
            setOpenLoadingSpinner(true);
            let observer_interval = setInterval(function () {
                if (!api_used) {
                    api_used = true;
                    clearInterval(observer_interval);

                    store.dispatch('Test/autoSubmitAnswer', {
                        current_page: state.current_page,
                        user_answers: state.user_answers,
                        timeout: true,
                    }).then(() => {
                        setOpenLoadingSpinner(false);
                        route_handler.route();
                    }).catch((error) => {
                        store.dispatch('Error/displayFromAPIError', error);
                    }).finally(() => {
                        setOpenBookmarkModal(false);
                        setOpenLoadingSpinner(false);
                    });
                }
            }, constant.API_RETRY_TIME);

        }

        /**
         * Auto submit the answer. If the API is in use (submitted by users), then skip the auto submit answers.
         * @param refresh_browser
         */
        function autoSubmitAnswer(refresh_browser = false) {
            if (!api_used) {
                api_used = true;
                store.dispatch('Test/autoSubmitAnswer', {
                    current_page: state.current_page,
                    user_answers: state.user_answers,
                    timeout: false,
                }).then(() => {
                    if (refresh_browser) {
                        window.location.reload();
                    }
                    if (store.getters.testState != constant.STATUS_TEST_IN_PROGRESS) {
                        route_handler.route();
                    }

                }).catch((error) => {
                    store.dispatch('Error/displayFromAPIError', error);
                }).finally(() => {
                    __updateLastAnswerSubmittedTimestamp()
                    api_used = false;

                    // hide timer to update time
                    setTimeout(() => {
                        state.sleep_detected = false
                    }, 300);
                })
            }
        }

        function toggleTimer(event) {
            if (test_settings.time_limits > 0) {
                let header_element_height = document.getElementById('test-header').clientHeight;
                let timer_element = document.getElementById("template__timer");
                if (timer_element != null) {
                    let target_position_y = timer_element.offsetTop + timer_element.clientHeight - header_element_height - 26;
                    if (event.detail.scrollTop > target_position_y) {
                        scrollPassPageTimer.value = true;
                    } else {
                        scrollPassPageTimer.value = false;
                    }
                } else {
                    scrollPassPageTimer.value = false;
                }
            }
        }


        function __updateLastAnswerSubmittedTimestamp() {
            state.last_answer_submitted_timestamp = Math.floor(Date.now() / 1000);
        }

        /**
         * Watch if error modal is closed and there isdisplayFromAPIError not answered quiz id exists.
         if not answered quiz id exists, then scroll to the quiz location
         */
        watch(() => store.getters.displayErrorModal, (to, from) => {
            if (from == true && to == false) {
                if (state.not_answered_quiz_ids.length > 0) {
                    setTimeout(() => {
                        TestHelper.scrollToQuestion(state.not_answered_quiz_ids[0]);
                    }, 100)
                }
            }
        });

        watch(() => state.display_feedback, (to, from) => {
            if (to == true) {
                //feed back page
                state.google_ad_slot = process.env.VUE_APP_GOOGLE_ADS_SLOT_FEEDBACK;
                TestHelper.dismissLoadingComponents(state, isLoadingSpinnerOpen);
            } else {
                state.google_ad_slot = process.env.VUE_APP_GOOGLE_ADS_SLOT_TEST;
            }
        });

        watch(() => state.display_contents, (to, from) => {
            // hide contents
            if (from == true && to == false) {
                TestHelper.fadeContents(false);
            }// show contents
            else if (from == false && to == true) {
                TestHelper.fadeContents(true);
                TestHelper.dismissLoadingComponents(state, isLoadingSpinnerOpen);
            }
        });


        //when bookmark modal or display error message is one, hide main contents from aria
        const testPageAriaHidden = computed(() => {
            if (isBookmarkModalOpen.value == true || store.getters.displayErrorModal) {
                return true
            } else {
                return false
            }
        })


        // default html setup method
        const {setupAppDefaultCssClasses} = htmlSetupUtil();


        return {
            state, goBack, goNext, updateUserAnswer, updateUserFlag, isNextPageAvailable,
            test_settings, saveAndExit, completeTest,
            displayError, isBookmarkModalOpen, setOpenBookmarkModal, jumpToPage,
            isLoadingSpinnerOpen, setOpenLoadingSpinner, userFullName, calculateTimeoutProgress, convertDurationString,
            timeoutCompleteTest, autoSubmitAnswer, toggleTimer, scrollPassPageTimer,
            offlineSoonMessage, offline_ref, setupAppDefaultCssClasses,
            testPageAriaHidden,
            setOpenCompleteTestModal, isCompleteTestModalOpen, isCompleteModalButtonDisabled
        };

    }
});
</script>

<style lang="scss" scoped>

:deep(.test-title-area) {
    padding-top: 34px;
}

.test-header {
    font-family: 'Helvetica';
    font-size: 14px;
    height: 46px;
    border-bottom: 1px solid $color-gray-02;
    position: fixed;
    width: 100%;
    z-index: 11;
    background-color: $color-white;

    &__left {
        float: left;
        z-index: 3;
    }

    &__right {
        display: flex;
        flex-wrap: wrap;
        float: right;
        z-index: 3;
        position: relative;
        height: 22px;

        line-height: 22px;
        margin-top: 12px;
        margin-bottom: 12px;

        font-family: Helvetica;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.02em;
        text-align: left;
    }
}

.bookmark-button {
    font-family: Helvetica;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;

    display: flex;
    align-content: center;
    height: 30px;
    bottom: 4px;
    --background: transparent;

    cursor: pointer;
    z-index: 4;
    position: relative;
    padding-right: 10px;
    //max-width:180px;

    .icon {
        margin-right: 8px;
        position: relative;
        width: 20px;
        height: 20px;
        background-size: 20px;
    }

    @include responsive(size-small) {
        left: 21px;
        margin-right: 14px;
        & > span {
            display: none;
        }
    }
}


.username {
    display: flex;
    align-content: center;

    /* Text vertically align */
    height: 22px;
    line-height: 22px;

    font-size: 14px;
    color: $color-gray-primary;
    margin-left: 14px;
    margin-right: 14px;
    position: relative;

    @include responsive(size-small) {
        margin-left: 0px;
        display: none;
    }

    .icon {
        margin-right: 8px;
        position: relative;
        width: 22px;
        height: 22px;
        background-size: 22px;
    }
}

#test-title-user-area {
    display: flex;
    margin-bottom: 12px;
    widht: 100%;


    .bookmark-button {
        display: flex;
        left: -14px;
        //max-width:160px;
        --background-hover: transparent;
        padding: 0px;
        @include responsive(size-small) {
            margin-right: 0px;
        }

        span {
            @include responsive(size-small) {
                display: none;
            }
        }
    }

    .username {
        width: calc(100% - 160px);
        margin-left: 0px;
        color: $color-gray-primary;

        @include responsive(size-small) {
            display: block;
            margin-bottom: 23px;
            width: calc(100% - 50px);
        }


        &.username-border {
            border-left: solid 2px $border-color-gray;
            padding-left: 17px;
            left: -9px;
        }

        .icon {
            position: relative;
            display: block;
            width: 31px;
            float: left;
        }

        .username-text {
            float: left;
            position: relative;
            display: block;
            text-overflow: ellipsis;
            width: calc(100% - 41px);
            overflow: hidden;
            white-space: nowrap;
        }
    }
}


.template {
    min-height: 967px;

    #test-content-wrapper {
        margin-top: 12px;

        .test-content:not(:first-of-type) {
            margin-top: $large-space;
        }
    }
}


.button-row {
    margin-top: $medium-space;
    margin-bottom: $medium-space;
}

.button-area {
    display: table;
}

.arrow-left {
    position: relative;
    top: 1px;

    margin-right: 3px;
    background-size: 8px 15px;
}

.button-next {
    --padding-end: 9px;

    .arrow-right {
        height: 11px;
        margin-left: 11px;
        position: relative;
    }
}


</style>
