export default Object.freeze({
    CM_DOMAIN: process.env.VUE_APP_CM_DOMAIN,
    STUDENT_INTERFACE_URL: process.env.VUE_APP_CM_DOMAIN + '/u',
    STUDENT_GO_BACK_URL: process.env.VUE_APP_CM_DOMAIN + '/online-test/start/return/group/',
    CACHE_ATTEMPTS_COUNT_PREFIX: 'a_',
    GROUP_TYPE_LINK: 'link',
    GROUP_TYPE_GROUP: 'group',

    // attempts
    ATTEMPTS_UNLIMITED: 0,

    // request param default php code
    PHP_REQUEST_PARAM: '<?=base64_encode(json_encode($_REQUEST))?>',


    CONTENTS_QUESTION: "question",

    QUESTION_MATCHING: "matching",
    QUESTION_TRUE_FALSE: "truefalse",
    QUESTION_MULTIPLE_CHOICE: "multiplechoice",
    QUESTION_MULTIPLE_RESPONSE: "multipleresponse",
    QUESTION_GRAMMAR: "grammar",
    QUESTION_ESSAY: "essay",
    QUESTION_FREE_TEXT: "freetext",

    // Status
    STATUS_LOGIN: 'login',
    STATUS_ACCESS_CODE: 'access_code',
    STATUS_USER_INFO: 'user_info',
    STATUS_PAYMENT: 'payment',
    STATUS_TEST_INTRO: 'test_intro',
    STATUS_TEST_IN_PROGRESS: 'test_in_progress',
    STATUS_TEST_COMPLETE: 'test_complete',
    STATUS_LOGOUT: 'logout',

    // LANGUAGE CODE
    LANG_CODE_KOREA: 'ko',
    LANG_CODE_JAPAN: 'ja',
    LANG_CODE_CHINA: 'zh-tr',
    LANG_CODE_CHINA_SIMPLE: 'zh',


    /* Offline status
        - online : the cm server is on
        - offline: the cm server is offline,
        - backonline: the cm server is online from offline, but required user action to resume test.
     */
    STATUS_ONLINE: 'online', // online mode
    STATUS_OFF_LINE: 'offline',
    STATUS_BACK_ONLINE: 'back_online', // offline mode is closed. this requires to click button to refresh
    STATUS_OFFLINE_RESUME_TEST: 'offline_resume_test', // user trigger resume test in test page when server is back online
    STATUS_ONLINE_WAITING_SIM_USER_SLOT: 'waiting_sim_user_slot', // trigger


    //QUESTION
    QUESTION_NOT_ATTEMPT: 'not_attempt',  // not attempt and no answer
    QUESTION_NOT_ANSWERED: 'not_answered', // attempt but not answer for all options in question
    QUESTION_ANSWERED: 'answered', // attempt and answer all options in question,

    API_RETRY_TIME: 300, // API retry time

    // THEMES
    CSS_ALIGN_LEFT: 'left',
    CSS_ALIGN_CENTER: 'center',

    FULL_WIDTH: "full_width",

    // CHEAT TYPE
    CHEAT_TYPE_TAMPER_RESULT: 'TAMPER_RESULT',

    // ERROR TYPE
    ERROR_NO_INTERNET: 'Network Error', // network error
    ERROR_MISSING_PARAM: 'missing_param',
    ERROR_DEFAULT: 'default',
    ERROR_MULTIPLE_BROWSER_DETECTED: 'test_error_multibrowser_text',
    ERROR_ACCESS_TOKEN_EXPIRED: 'ex_error_no_activity',
    ERROR_INVALID_ACCESS_CODE: 'ex_error_get_details_access_code_no_match',
    ERROR_GENERIC_SERVER_ERROR: 'ex_error_generic',
    // ERRORS should delete cookies
    ERROR_TEST_LIMIT_REACHED: 'ex_error_access_code_limit_reached', //this is only applied when error page is one
    ERROR_INVALID_QUIZ_ID: 'ex_error_no_test_assigned',
    ERROR_INVALID_PREVIEW_TEST: 'invalid_preview_test',

    ERROR_TEST_UNAVAILABLE: 'ex_error_no_test_unavailable',
    ERROR_IP_NOT_ALLOWED: 'ip_not_allowed',
    ERROR_INACTIVE_ACCOUNT: 'inactive_account',
    ERROR_AUTH_TOKEN_EXPIRED: 'ex_error_no_session',
    ERROR_TOO_MANY_ATTEMPTS: 'ex_error_too_many_attempts',
    ERROR_NOT_UNIQUE_CM_USER_ID: 'ex_error_not_unique_cm_user_id',
    ERROR_NO_CM_USER_ID: 'ex_error_no_no_cm_user_id',
    ERROR_INVALID_RESUME_LINK: 'ex_error_no_group_1|ex_error_no_group_2',
    ERROR_NO_CREDIT_PAID_USER: 'ex_error_no_credits_paid_user',
    ERROR_NO_CREDIT_FREE_USER: 'ex_error_no_credits_free_user',
    ERROR_INVALID_CLIENT_REFERER :'invalid_client_referer',
    ERROR_COUNTRY_NOT_ALLOWED: 'country_not_allowed',
    ERROR_NO_QUESTIONS_IN_TEST: 'test_error_1_text',
    ERROR_OFF_LINE_MODE: 'offline',
    ERROR_NEW_TEST_DETECTED: 'new_test_detected',
    ERROR_RETRY_TEST: 'after_set_cookies_try_again',
    ERROR_TOO_MAY_SIM_USERS: 'too_many_sim_users',
    ERROR_MESSAGES:
        {
            'default': 'Your session has expired. Please go back to the test to try again.',
            'missing_param': 'A required link parameter is missing to take this online test.<br/><br/>Please contact the person who gave you this online test to correct this for you.',
            'ex_error_too_many_attempts': "Thank you.<br/><br/>You have now reached the maximum amount of attempts allowed for this test. It cannot be taken again.",
            'test_unavailable': 'This online test is currently not available to be taken at this time due to the applied date and time restrictions.<br/><br/>Please contact the person who gave you this online test for more information.',
            'Network Error': 'No Internet connection. Please try again.',
            'TAMPER_RESULT': 'The attempt to change results has been detected and recorded.<br/><br/>' +
                'The results have not been changed.<br/><br/>' + 'The Exam is now Finished.',
            'after_set_cookies_try_again':'Go back and try again.',
        }


})