import UserAnswer from "@/store/model/userAnswer";
import constant from "../../constant";

export default class TestSection {
    constructor(section, test_settings) {
        this.settings = section.settings;
        this.pages = [];
        this.user_answers = {};
        this.number_of_question = 0;
        this.first_page_index = null;
        this.last_page_index = null;
        this.__setPages(section.pages, test_settings);
    }

    __setPages(pages, test_settings) {
        pages.forEach((page, index) => {
            this.addPage(page, test_settings);
        });
    }

    /**
     * Add new page into the Test Data class
     * - add questions list
     * - add user answers
     * @param page
     */
    addPage(page, test_settings) {
        this.pages[page.page] = {};
        this.pages[page.page]['page'] = page.page;
        this.pages[page.page]['prev_question_count'] = page.prev_question_count;
        for (let i = 0; i < page.contents.length; i++) {
            let single_content = page.contents[i];

            // if content type == questions => check flagged and user answers
            if (single_content.type == "question") {
                this.number_of_question = this.number_of_question + 1;
                if (this.user_answers[page.page] === undefined) {
                    this.user_answers[page.page] = new Object();
                }

                let user_answer = this.__generateUserAnswer(single_content, test_settings);



                this.user_answers[page.page][single_content.id] = user_answer;
                delete single_content.user_answers;
            }
        }

        this.pages[page.page]['contents'] = page.contents;
        if (this.first_page_index === null || this.first_page_index > page.page) {
            this.first_page_index = page.page;
        }
        if (this.first_page_index === null || this.last_page_index < page.page) {
            this.last_page_index = page.page;
        }

    }

    getPage(page_num) {
        if (page_num in this.pages) {
            return this.pages[page_num];
        } else {
            return [];
        }

    }

    getPageUserAnswer(page_num) {

        if (page_num in this.user_answers) {
            return this.user_answers[page_num];
        } else {
            return new Object();
        }
    }

    __generateUserAnswer(single_content, test_settings){
        let user_answer = new UserAnswer();

        user_answer.setType(single_content.sub_type);

        if ('user_answer' in single_content) {
            user_answer.setAnswerFromQuestionObject(single_content);

        }
        if ('flagged' in single_content) {
            user_answer.setFlagged(single_content['flagged']);
        }

        if(!test_settings.allow_click_previous){
            user_answer.setNonEditable();
        }

        if(single_content.sub_type == constant.QUESTION_MATCHING){
            user_answer.expected_num_of_answer = single_content.options.clues.length;
        }

        return user_answer
    }
}