<template>
  <ion-page id="page">
    <ion-content :fullscreen="true" scrollX="true" scrollY="true" v-bind:class="setupAppDefaultCssClasses()">

      <Header/>

      <div id="contents" class="template">
        <div style="margin-left:16px;margin-bottom:32px;" v-if="state.theme.show_ads">
          <google-ads :google-ad-slot="googleAdSlot"></google-ads>
        </div>

        <test-title :display-price="false" :display-title="false"></test-title>

        <div class="padding-top-medium margin-bottom-medium"
             v-if="state.settings.time_limits>0  && state.result.time_out">
          <banner :message="'<strong>'+state.language_set.note+'</strong>: '+state.language_set.time_limit_reached"
                  banner-type="warning">
          </banner>
        </div>

        <div class="padding-top-medium margin-bottom-medium"
             v-if="state.result.completed_by_teacher==true">
          <banner message="This test has recently been canceled and set to finished by the account Administrator."
                  banner-type="warning">
          </banner>
        </div>

        <test-result-card :title="state.theme.test_title"
                          :points-available="state.result.points_available"
                          :points-scored="state.result.points_scored"
                          :points-percentage="state.result.points_percentage"
                          :duration="state.result.duration"
                          :passed="state.result.pass"
                          :date-started="state.result.date_started"
                          :date-finished="state.result.date_finished"
                          :feedback="state.result.test_feedback"
                          :display-score="state.settings.display_result_score"
                          :settings="state.settings"
                          :certificate-url="state.result.certificate_url"
                          :disable-print-button="state.disable_print_button"
                          :require-grading="state.correct_stats.require_grading > 0"
                          :receipt-url="state.result.receipt_url"
                          v-on:print="print()"
        ></test-result-card>

        <ion-card class="template__continue margin-top-large"
                  v-if="state.result.return_url!=null && state.result.return_url!=''">
          <div class="template__continue__text">
            {{ state.language_set.quiz_continue_here_title }}
          </div>
          <ion-button class="template__continue__button continue-button word-wrap" @click="openReturnUrl()" text-wrap>
            <span class="continue-button-text">{{ returnUrlText }}</span>
          </ion-button>

        </ion-card>

        <div v-if="state.result.category_statistics">
          <category-stats-card :category-statistics="state.result.category_statistics"
                               :display-points="state.settings.display_result_score"/>
        </div>


        <div class="margin-top-large" v-if="state.result.question_feedbacks">

          <question-feedback-list
              :question-feedbacks="state.result.question_feedbacks"
              :test-sections="state.result.test_sections"
              :question-correct-stats="state.correct_stats"
              :incorrect-only="state.settings.display_results_incorrect_questions_only"
              :display-bookmarks="state.settings.allow_click_previous"
          />
        </div>

        <div style="margin-top:50px; margin-left:16px;margin-bottom:32px;" v-if="state.theme.show_ads">
          <google-ads :google-ad-slot="googleAdSlot"></google-ads>
        </div>

        <CmPowerByBanner></CmPowerByBanner>

      </div>


    </ion-content>
  </ion-page>
</template>

<script>
import {IonButton, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import store from "@/store";
import Header from "../components/html/Header";
import TestTitle from "../components/html/TestTitle";
import TestResultCard from "../components/result/TestResultCard";
import CategoryStatsCard from "../components/result/CategoryStatsCard";
import QuestionFeedbackList from "../components/result/QuestionFeedbackList";
import Banner from "../components/html/Banner";
import authHandler from "../router/authHandler";
import CmPowerByBanner from "../components/html/CmPowerByBanner";
import GoogleAds from "../components/html/GoogleAds";
import htmlSetupUtil from "../utils/htmlSetupUtil";
import googleTagManagerUtils from "@/utils/googleTagManagerUtils";

export default defineComponent({
  name: 'Test',
  components: {
    QuestionFeedbackList,
    IonContent,
    IonPage,
    IonButton,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    TestResultCard,
    CategoryStatsCard,
    Banner,
    IonCard,
    IonIcon,
    CmPowerByBanner,
    GoogleAds,
    TestTitle
  },
  mounted() {
    /**
     * Dislable copy & paste depedning on the setup
     */
    const {disablePaste, disableCopy} = htmlSetupUtil();
    // disable copy & paste if it is required.
    disablePaste();
    disableCopy();

    googleTagManagerUtils().pushEventData("Test Finished", "");

    /**
     * Remove before unload event. The APP.vue file is loaded later than this page,
     * unloadEvent setup should be delayed until App.vue file is loaded
     *
     * Mount order : result.vue => app.vue.
     * We had put very minimal amount of delay to ensure, popupUnload Event is removed from result page
     * when refresh the result page.
     */
    setTimeout(() => {
      window.removeEventListener('beforeunload', this.$popupUnLoadEvent);
    }, 1500);
  },

  setup(props, ctx) {
    const state = reactive({
      result: store.state.Test.data.result,
      user: store.state.User.user,
      theme: store.state.Test.settings.theme,
      correct_stats: {
        all: 0,
        correct: 0,
        partial: 0,
        incorrect: 0,
        require_grading: 0,
        has_bookmarked: 0
      },
      language_set: store.getters.languageSet,
      settings: store.getters.testSettings,
      disable_print_button: false,
    });
    // ensure clear auto submit timer from the test page
    store.dispatch('clearTestAutoSubmitTimer');


    const googleAdSlot = process.env.VUE_APP_GOOGLE_ADS_SLOT_RESULT;
    const quiz_id = new URL(location.href).searchParams.get('quiz');

    authHandler.setExpireTokenIn15m(quiz_id);
    authHandler.deleteIframeToken(quiz_id);

    __setCorrectStats();

    function __setCorrectStats() {
      for (let question_id in state.result.question_feedbacks) {
        let feedback = state.result.question_feedbacks[question_id];
        if (feedback) {
          if (feedback.full_mark) {
            state.correct_stats.correct += 1;
          } else if (feedback.grade_required) {
            state.correct_stats.require_grading += 1;
          } else if (Number(feedback.point_scored) > 0) {
            state.correct_stats.partial += 1;
          } else {
            state.correct_stats.incorrect += 1;
          }
          if (feedback.has_bookmarked) {
            state.correct_stats.has_bookmarked += 1;
          }
        }
        state.correct_stats.all += 1;
      }
    }


    function openReturnUrl() {
      let return_url = state.result.return_url.replace(/&amp;/g, '&');
      window.open(return_url, '_blank').focus();
    }


    /**
     * Get Return URL Text.
     * If Return URL text exists, then return it.
     * Otherwise get the origin of the return URL.
     * @type {ComputedRef<unknown>}
     */

    const returnUrlText = computed(() => {
      if (state.result.return_url_text != null && state.result.return_url_text != '') {
        return state.result.return_url_text
      } else {
        let return_url = state.result.return_url.replace(/&amp;/g, '&');
        let domain = (new URL(return_url));
        return domain.origin;
      }
    });

    function print() {

      let ion_content = document.querySelector('ion-content');
      let print_content = document.querySelector('#print-content');
      print_content.innerHTML = ion_content.innerHTML;

      store.dispatch('setPrintButtonClicked');
      setTimeout(() => {
        window.print();
        store.dispatch('unsetPrintButtonClicked');
      }, 50)
    }

    // default html setup method
    const {setupAppDefaultCssClasses} = htmlSetupUtil();

    return {
      state, openReturnUrl, returnUrlText, googleAdSlot, print, setupAppDefaultCssClasses
    };

  },


});
</script>

<style lang="scss" scoped>

:deep(.header.default-header) {
  min-height: 58px;
}

.template {

  &__continue {
    padding: 24px 24px 24px 32px;
    margin: 0px;
    min-height: 138px;
    width: 100%;
    border-radius: 5px;
    display: block;


    &__text {
      vertical-align: middle;
      font-family: Arial;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.02em;
    }


    &__button {
      position: relative;
      margin-top: $medium-space;
      max-width: 100%;

    }
  }
}

.button-row {
  margin-top: $medium-space;
  margin-bottom: $medium-space;
}

.word-wrap {
  white-space: normal;
  height: auto;
  min-height: 38px;
  word-break: break-all;
}

.continue-button-text {
  min-height: 38px;
  line-height: 38px;
}


</style>