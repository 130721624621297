<template>
  <banner v-if="incorrectOnly"
          :message="state.language_set.show_incorrect_only_text"
          banner-type="info" style="margin-top:15px; margin-bottom:15px;">  </banner>
  <!--  <ion-segment mode="md" @ionChange="segmentChanged($event)">-->
  <ion-segment class="list-menu" scrollable="true" mode="md" :value="state.feedback_mode" @ionChange="segmentChanged($event)">
    <ion-segment-button :value="LIST_ALL_QUESTION" data-cy="feedback-all-btn">
      <ion-label class="ion-text-wrap">{{ state.language_set.link_question_filter_all }}</ion-label>
    </ion-segment-button>
    <ion-segment-button v-if="!incorrectOnly" :value="LIST_CORRECT_QUESTION" data-cy="feedback-correct-btn" :disabled="questionCorrectStats.correct == 0">
      <ion-label class="ion-text-wrap">{{ questionCorrectStats.correct }} {{ state.language_set.link_question_filter_correct }}</ion-label>
    </ion-segment-button>
    <ion-segment-button :value="LIST_PARTIALLY_CORRECT_QUESTION" data-cy="feedback-partially-correct-btn" v-if="questionCorrectStats.partial>0">
      <ion-label class="ion-text-wrap">{{ questionCorrectStats.partial }} {{ state.language_set.link_question_filter_partial_correct }}
      </ion-label>
    </ion-segment-button>
    <ion-segment-button :value="LIST_INCORRECT_QUESTION" data-cy="feedback-incorrect-btn" :disabled="questionCorrectStats.incorrect == 0">
      <ion-label class="ion-text-wrap">{{ questionCorrectStats.incorrect }} {{ state.language_set.link_question_filter_incorrect }}
      </ion-label>
    </ion-segment-button>
    <ion-segment-button :value="LIST_BOOKMARKED" data-cy="feedback-bookmarked-btn" v-if="displayBookmarks" :disabled="questionCorrectStats.has_bookmarked == 0">
      <ion-label class="ion-text-wrap">{{ questionCorrectStats.has_bookmarked }} {{ state.language_set.link_question_filter_flagged }}
      </ion-label>
    </ion-segment-button>

    <ion-segment-button v-if="questionCorrectStats.require_grading > 0" :value="LIST_REQUIRE_GRADING">
      <ion-label class="ion-text-wrap">{{ questionCorrectStats.require_grading }}
        {{ state.language_set.link_question_filter_require_grading }}
      </ion-label>
    </ion-segment-button>
  </ion-segment>


  <div class="feedback" v-for="(content,index) in questionList" :key="content.type+content.id" :id="'question-'+content.id" :data-cy="'question-idx-'+content.question_index">
    <question-feedback v-if="content.type=='question' && questionFeedbacks[content.id]"
                       :question="content"
                       :user-answer="state.user_answers[content.id]"
                       :question_index="content.question_index"
                       :num-of-question=" questionCorrectStats.all"
                       :feedback="questionFeedbacks[content.id]"
                       :display-bookmarks="displayBookmarks"
    ></question-feedback>
  </div>

</template>


<script>
import {IonLabel, IonSegment, IonSegmentButton} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import QuestionFeedback from "../test/QuestionFeedback";
import store from '@/store';
import Banner from "../html/Banner";

export default defineComponent({
  name: 'QuestionFeedbackList',
  components: {
    IonSegment,
    IonSegmentButton,
    IonLabel,
    QuestionFeedback,
    Banner
  },
  props: {
    questionFeedbacks: Object,
    testSections: Object,
    questionCorrectStats: Object,
    incorrectOnly: Boolean,
    displayBookmarks: Boolean
  },
  setup(props, ctx) {
    // List constants to display
    const LIST_ALL_QUESTION = 0;
    const LIST_CORRECT_QUESTION = 1;
    const LIST_PARTIALLY_CORRECT_QUESTION = 2;
    const LIST_INCORRECT_QUESTION = 3;
    const LIST_REQUIRE_GRADING = 4;
    const LIST_BOOKMARKED = 5;

    const state = reactive({
      feedback_mode: LIST_ALL_QUESTION,
      correct_stats: props.questionCorrectStats,
      user_answers: [],
      num_of_question: 0,
      language_set: store.getters.languageSet,
      question_list: [],
    });

    state.num_of_questions = props.questionCorrectStats.all;
    __setUserAnswers();
    __setQuestionFeedbackList();

    function segmentChanged(event) {
      state.feedback_mode = parseInt(event.detail.value);
    }


    function __setUserAnswers() {
      for (let section_id in props.testSections) {

        let section = props.testSections[section_id];
        state.num_of_questions += section.number_of_question;

        for (let page_id in section.user_answers) {
          let user_answers = section.user_answers[page_id];
          for (let question_id in user_answers) {
            state.user_answers[question_id] = user_answers[question_id];
          }
        }
      }
    }

    function __setQuestionFeedbackList() {
      let question_list = [];
      let question_index = 1;
      for (let section_id in props.testSections) {
        let section = props.testSections[section_id];

        for (let page_id in section.pages) {
          let page = section.pages[page_id];

          for (let content_index in page.contents) {

            let content = page.contents[content_index];

            if (content.type == 'question') {

              content.question_index = question_index++;

              let feedback = props.questionFeedbacks[content.id];
              if (feedback) {
                let point_scored = 0;
                if (feedback && feedback.point_scored) {
                  let point_scored = Number(feedback.point_scored);
                }
                question_list.push(content);
              }
            }
          }
        }
      }
      state.question_list = question_list;
    }

    const questionList = computed(() => {
      // set default value
      if(state.feedback_mode === LIST_ALL_QUESTION) {
        return state.question_list;
      }

      let question_list = [];

      for (let question of state.question_list) {
        let feedback = props.questionFeedbacks[question.id];

        if (feedback) {
          let point_scored = 0;
          if (feedback && feedback.point_scored) {
            point_scored = Number(feedback.point_scored);
          }

          switch (state.feedback_mode) {
            case LIST_CORRECT_QUESTION:
              if (feedback.full_mark) {
                question_list.push(question);
              }
              break;
            case LIST_PARTIALLY_CORRECT_QUESTION:
              if (!feedback.grade_required && !feedback.full_mark && point_scored > 0) {
                question_list.push(question);
              }
              break;
            case LIST_INCORRECT_QUESTION:
              if (!feedback.grade_required && !feedback.full_mark) {
                question_list.push(question);
              }
              break;
            case LIST_REQUIRE_GRADING:
              if (feedback.grade_required) {
                question_list.push(question);
              }
              break;
            case LIST_BOOKMARKED:
              if (feedback.has_bookmarked) {
                question_list.push(question);
              }
              break;
          }
        }
      }

      return question_list;
    });


    return {
      state,
      LIST_ALL_QUESTION,
      LIST_CORRECT_QUESTION,
      LIST_PARTIALLY_CORRECT_QUESTION,
      LIST_INCORRECT_QUESTION,
      LIST_REQUIRE_GRADING,
      LIST_BOOKMARKED,
      questionList,
      segmentChanged
    };
  },


});
</script>

<style scoped lang="scss">
@import '../../theme/classmarker_theme';

.list-menu {
  & ion-segment-button {
    text-transform: lowercase;
    font-family: $default-font-family;
    font-size: $default-font-size;

    &.segment-button-checked {
      font-weight: bold;
    }
  }
}

.feedback {
  margin-top: $large-space
}

</style>